import MissingImage from "@/public/images/image-missing.png"
import _get from "lodash/get"
import _isEmpty from "lodash/isEmpty"

const getProcessedInstallServiceData = (
  productServiceData,
  presetConfigs,
  swatchUrl,
  persona,
  currencySign
) => {
  const productCardProps = {
    presetConfigs: presetConfigs ? JSON.parse(presetConfigs) : "",
    swatchUrl: swatchUrl ?? "",
    images: MissingImage,
  }
  if (!_isEmpty(productServiceData)) {
    // get product images
    let imgSrc = ""
    if (productServiceData["productImages.url_s"]) {
      imgSrc = productServiceData["productImages.url_s"] ?? ""
    } else if (productServiceData["productImages.url_ss"]) {
      imgSrc = productServiceData["productImages.url_ss"][0] ?? ""
    }

    if (imgSrc.length) {
      const imgParts = imgSrc?.split("/")
      const imgId = imgParts[imgParts.length - 1]
      productCardProps.images = imgId
    }

    // get product colors
    if (productServiceData["Color.SKU.Details_ss"]) {
      const colors = productServiceData["Color.SKU.Details_ss"] ?? []
      productCardProps.colors = colors.filter(item => {
        const color = item.split("|")
        return color[4] === "false" || Date.parse(color[4]) > Date.now()
      })
    } else {
      productCardProps.colors = [
        productServiceData["SKUColorSwatchFilename_s"] ?? "",
      ]
    }

    // get product web features
    if (productServiceData["ProductWebFeatures_s"]) {
      productCardProps.productFeatures =
        [productServiceData["ProductWebFeatures_s"]] ?? []
    } else {
      productCardProps.productFeatures =
        productServiceData["ProductWebFeatures_ss"] ?? []
    }
    productCardProps.currencySign = currencySign
    productCardProps.productCategory = _get(
      productServiceData,
      "Product_Category",
      ""
    )
    productCardProps.name = _get(productServiceData, "productName_s", "")
    productCardProps.brandName = _get(
      productServiceData,
      "RegionBrandName_s",
      ""
    )
    productCardProps.description = _get(
      productServiceData,
      "ProductNarrativeDescription_s",
      ""
    )
    productCardProps.narrativeDescription = _get(
      productServiceData,
      "ProductNarrativeDescription_s",
      ""
    )
    productCardProps.price = _get(
      productServiceData,
      `priceStartingAt.${persona}_d`,
      0
    )
    productCardProps.discountedPrice = _get(
      productServiceData,
      `discountedPriceStartingAt.${persona}_d`,
      0
    )
    productCardProps.discountPercent = parseInt(
      _get(productServiceData, `priceList.${persona}.saleOffer_s`, 0)
    )
    productCardProps.slug = _get(productServiceData, "slug_s", "")
    productCardProps.customerBrand = _get(
      productServiceData,
      "CustomerFacingBrand_s",
      ""
    )
    productCardProps.skuId = _get(productServiceData, "masterSKU_s", "")
    let tempColorDetails = _get(
      productServiceData,
      "Color.SKU.Details_s",
      _get(productServiceData, "Color.SKU.Details_ss", null)
    )
    if (tempColorDetails) {
      if (typeof tempColorDetails !== "string" && tempColorDetails[0]) {
        tempColorDetails = tempColorDetails[0]
      }
      productCardProps.customerFacingSKU = tempColorDetails.split("|")[5] || ""
    }
    productCardProps.badgeVal = _get(
      productServiceData,
      `priceList.${persona}.saleOffer_s`,
      ""
    )
    productCardProps.globalDiscount =
      productCardProps.price -
      _get(
        productServiceData,
        `priceList.${persona}.finalPrice_d`,
        productCardProps.price
      )
    productCardProps.priceType = ""
  }
  return productCardProps
}

export { getProcessedInstallServiceData }
