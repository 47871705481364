import React, { useEffect, useState, useRef, memo } from "react"
import { useSelector, useDispatch } from "react-redux"
import { selectAuthState } from "@/store/features/authSlice"
import { selectCartState, addToCart, setCart } from "@/store/features/cartSlice"
import {
  showPreviewCart,
  setConsolidatedZipCodes,
  selectGenericState,
} from "@/store/features/genericSlice"
import { addToCartItem } from "@/utils/addTocart"
import UpSellInstallServicesCarousalSlick from "@/components/Shared/UpsellInstallServices/InstallServiceCarousalSlick"
import {
  getUserPersona,
  calculateCartData,
  sanitizeDOMString,
  getUpSellAuthData,
  getProcessedProductInfoBasedOnLineItems,
} from "@/utils/helper"
import {
  getProductInventory,
  getListOfRequiredSkus,
  getProductDataFromSkuIds,
  getZipCodes,
} from "@/components/Shared/UpsellInstallServices/api"
import { getProcessedInstallServiceData } from "@/components/Shared/UpsellInstallServices/utils"
import {
  locationState,
  setUtilityLocation,
} from "@/store/features/locationSlice"
import _get from "lodash/get"
import _isEmpty from "lodash/isEmpty"
import Input from "@/components/core/Input/Input"
import styles from "@/components/Shared/UpsellInstallServices/index.module.scss"
import { useTranslation } from "next-i18next"
import { getTranslations } from "@/components/Shared/UpsellInstallServices/localization"
import {
  addAnalyticsForheader,
  addAnalyticsWhenupsellvisible,
} from "@/components/Shared/UpsellInstallServices/analytics"
import ProductCardForUpsell from "@/components/Shared/UpsellInstallServices/ProductCardForUpsell"
import { NUMERIC_REGX } from "@/constants/index"

const UpSellInstallServices = ({
  authData = {},
  isRequireToLookupFullData = false,
  upSellProductData = [],
  occupyFullWidth = false,
}) => {
  const slickRef = useRef(null)
  const { cart } = useSelector(selectCartState)
  const { isAuth, user: { firstName = "" } = {} } = useSelector(selectAuthState)
  const { utilityLocation = "" } = useSelector(locationState)
  const { consolidatedZipCodes = [] } = useSelector(selectGenericState)
  const { t } = useTranslation("common")
  const staticText = getTranslations(t)
  const { search = "Search" } = staticText
  const dispatch = useDispatch()
  const [upSellData, setUpSellData] = useState([])
  const [zipCodeVal, setZipCodeVal] = useState(utilityLocation)
  const [showInput, setShowInput] = useState(utilityLocation ? false : true)
  const [loading, setLoading] = useState(true)
  const [isUpsellDataAvailable, setIsUpsellDatAvailable] = useState(false)
  const [isDataAvailToProceed, setIsDataAvailToProceed] = useState(false)
  const [isSearchClick, setIsSearchClick] = useState(false)

  const persona = getUserPersona()

  useEffect(() => {
    if (utilityLocation) setZipCodeVal(utilityLocation)
  }, [utilityLocation])

  useEffect(() => {
    return () => {
      delete window.isUpsellAnalaticsLoaded
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  useEffect(() => {
    if (upSellData.length) {
      window.addEventListener("scroll", handleScroll)
    }
  }, [upSellData])

  const {
    headerAuthData: {
      userTitleUpSell = "",
      zipLabelUpSell = "",
      zipChangeLabelUpSell = "",
    } = {},
    productCard: productCardAuthData = {},
    upSellServiceData: {
      presetConfigsUpSell: presetConfigs = "",
      swatchUrlUpSell: swatchUrl = "",
      jsonFilePathUpSell = "",
      isForPostPurchase = false,
      recommendationTitle = "",
      serviceAvailableTitleUpSell = "",
    } = {},
  } = getUpSellAuthData(authData, isRequireToLookupFullData)

  useEffect(() => {
    if (!_isEmpty(cart) && !isForPostPurchase) {
      const { lineItems = [] } = cart
      if (lineItems.length) fetchSkusBasedOnLineItems()
      else {
        setIsDataAvailToProceed(false)
        setLoading(false)
      }
    }
  }, [cart, isAuth])

  useEffect(() => {
    if (isForPostPurchase) {
      processWithPostPurchaseData()
    } else {
      if (isRequireToLookupFullData) {
        setIsDataAvailToProceed(false)
        setLoading(false)
      }
    }
  }, [isForPostPurchase])

  const performZipCodeLookUp = async userZipCodeVal => {
    let consolidatedZips = consolidatedZipCodes
    if (consolidatedZipCodes.length < 1) {
      try {
        const { data: { zipcode = [] } = {} } = await getZipCodes(
          jsonFilePathUpSell
        )
        dispatch(setConsolidatedZipCodes(zipcode))
        consolidatedZips = zipcode
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err, "err in fetching consolidated zip code")
      }
    }
    let availableSkuIdsForService = []
    for (const zipKey of consolidatedZips) {
      const key = Object.keys(zipKey)[0]
      for (const zipInnerKey of zipKey[key]) {
        const zipcode = Object.keys(zipInnerKey)[0]
        if (zipcode === userZipCodeVal) {
          availableSkuIdsForService = zipInnerKey[zipcode]
          break
        }
      }
      if (availableSkuIdsForService.length) break
    }
    return availableSkuIdsForService
  }

  const processWithPostPurchaseData = async () => {
    if (upSellProductData.length) {
      const {
        productItems = [],
        currency,
        lwAppName,
      } = await getProcessedProductInfoBasedOnLineItems(upSellProductData)

      mapProductDataWithServicesData(productItems, lwAppName, currency)
    }

    return null
  }

  const fetchSkusBasedOnLineItems = async () => {
    calculateCartData({ cart: sanitizeDOMString(cart) }, true).then(res => {
      const {
        cartItems,
        lwAppName,
        cart: { price: { currency } = {} } = {},
      } = res
      mapProductDataWithServicesData(cartItems, lwAppName, currency)
    })
  }

  const mapProductDataWithServicesData = async (
    productItems = [],
    lwAppName = "",
    currency = ""
  ) => {
    if (!productItems.length) {
      setIsDataAvailToProceed(false)
      return
    }
    const persona = getUserPersona()
    const productCartItems = productItems.filter(
      item => !item?.sku?.includes("INSTALL-SERVICE")
    )
    const installServicesSkus = []
    productCartItems.forEach(item => {
      const { associatedServices = [] } = item
      if (associatedServices.length) {
        installServicesSkus.push(...associatedServices)
      }
    })
    const skuIds = Array.from(new Set(installServicesSkus))
    const skusServiceable = await performZipCodeLookUp(zipCodeVal)
    const availableSkusToServe = skusServiceable.filter(value =>
      skuIds.includes(value)
    )
    getProductDataFromSkuIds(persona, availableSkusToServe, lwAppName).then(
      async res => {
        let processedInstallServiceData = []
        if (res?.data) {
          const rawInstallServiceData = _get(res.data, "response.docs", [])
          processedInstallServiceData = rawInstallServiceData.map(product =>
            getProcessedInstallServiceData(
              product,
              presetConfigs,
              swatchUrl,
              persona,
              currency
            )
          )
        }
        const productAndServiceData = []
        productCartItems.forEach(item => {
          const { associatedServices = [] } = item
          const serviceData =
            processedInstallServiceData.find(
              serviceItem => serviceItem.skuId === associatedServices[0]
            ) ?? {}
          let installServiceData = {}
          if (!_isEmpty(serviceData)) {
            installServiceData = {
              ...serviceData,
              quantity: item.quantity,
            }
          }
          productAndServiceData.push({
            cartProductData: item,
            installServiceData,
          })
        })
        const parentSkus = productAndServiceData.map(
          item => item?.cartProductData?.customerFacingSKU
        )
        const slugAndSkus = productAndServiceData.map(item => ({
          cSku: item?.cartProductData?.customerFacingSKU,
          slug: item?.cartProductData?.slug,
          sku: item?.cartProductData?.sku,
        }))
        const listOfRequiredSkus = []
        for (const item of slugAndSkus) {
          const { cSku = "", slug = "", sku = "" } = item ?? {}
          const {
            data: { response: { docs: requiredItemsSku = [] } = {} } = {},
          } = (await getListOfRequiredSkus(persona, slug, sku, lwAppName)) ?? {}
          if (requiredItemsSku.length) {
            const { CrossSellRecommendationSKUList_ss: requiredSkuList = [] } =
              requiredItemsSku[0]
            if (requiredSkuList.length) {
              listOfRequiredSkus.push({
                parentSku: cSku,
                requiredSkus: requiredSkuList,
              })
            }
          }
        }
        const requiredSkus = listOfRequiredSkus
          .map(item => item.requiredSkus)
          .flat(1)

        // Executing second API...
        const { data = {} } =
          (await getProductDataFromSkuIds(persona, requiredSkus, lwAppName)) ??
          {}
        const skuidString = requiredSkus.join(",")
        if (!_isEmpty(data) && skuidString) {
          const { response: { docs: requiredSkusProductData = [] } = {} } = data

          const { data: { results: productInventory = [] } = {} } =
            (await getProductInventory(skuidString)) ?? {}

          if (requiredSkusProductData.length) {
            listOfRequiredSkus.forEach(item => {
              const index = parentSkus.indexOf(item.parentSku)
              if (index > -1) {
                const requiredItemsProductData = requiredSkusProductData.filter(
                  productItem => item.requiredSkus?.includes(productItem.sku_s)
                )
                requiredItemsProductData.forEach((requiredProItem, index) => {
                  const inventoryObj =
                    productInventory.find(
                      inventoryItem =>
                        requiredProItem.sku_s === inventoryItem.sku
                    ) ?? {}
                  requiredItemsProductData[index].availableQuantity =
                    inventoryObj?.availableQuantity ?? 0
                })
                productAndServiceData[index].requiredItems =
                  requiredItemsProductData
              }
            })
          }
        }
        const filteredProductAndServiceData = productAndServiceData.filter(
          item =>
            item?.requiredItems?.length || !_isEmpty(item?.installServiceData)
        )
        setIsUpsellDatAvailable(filteredProductAndServiceData.length)
        setUpSellData(filteredProductAndServiceData)
        setLoading(false)
        setIsDataAvailToProceed(true)
        setUtilityInfo()
      }
    )
  }

  const handleAddtoCartResponse = ({ payload }) => {
    if (payload) {
      dispatch(setCart(payload))
    }
  }

  const addServiceToCart = async items => {
    const payload = await addToCartItem([...items])
    const body = {
      actions: payload,
    }

    dispatch(addToCart(body))
      .unwrap()
      .then(res => {
        dispatch(showPreviewCart())
        handleAddtoCartResponse(res)
      })
  }

  const handleAddToCart = (item, qty = 1) => {
    const {
      installServiceData: { skuId = "", customerBrand = "", quantity = 1 } = {},
    } = item

    addServiceToCart([
      {
        sku: skuId,
        quantity: qty ?? quantity,
        brand: customerBrand,
      },
    ])
  }

  const setUtilityInfo = () => {
    if (zipCodeVal) {
      dispatch(setUtilityLocation({ utilityLocation: zipCodeVal }))
    }
  }

  const handleZipSerches = (type, sI = false) => {
    setShowInput(!showInput)

    if (showInput) {
      setIsSearchClick(true)
      if (!_isEmpty(cart) && !isForPostPurchase) {
        fetchSkusBasedOnLineItems()
      } else {
        processWithPostPurchaseData()
      }
      if (slickRef.current) slickRef.current.slickGoTo(0)
    }
    addAnalyticsForheader(
      zipCodeVal,
      type,
      recommendationTitle,
      serviceAvailableTitleUpSell
    )
  }

  const handleZipChange = e => {
    const { target: { value = "" } = {} } = e

    if (value && !NUMERIC_REGX.test(value)) {
      return
    }
    setZipCodeVal(value)
  }

  const handleScroll = e => {
    const scrollpostion = document.querySelector(
      ".upsell-modal-header"
    )?.offsetTop
    setIsSearchClick(false)
    const yscrollposition = window.scrollY
    if (yscrollposition >= scrollpostion - 300) {
      if (!window.isUpsellAnalaticsLoaded) {
        const {
          cartProductData = {},
          requiredItems = [],
          installServiceData = {},
        } = upSellData.length ? upSellData[0] : {}

        const installServiceDataAnlytics = !_isEmpty(installServiceData)
          ? {
              ["ProductDescriptionProductShort_s"]:
                installServiceData?.narrativeDescription,
              ["Product_Category"]: installServiceData?.productCategory,
              image: installServiceData?.productImageUrl,
              unitPrice: installServiceData?.discountedPrice,
              customerFacingSKU: installServiceData?.customerFacingSKU,
              productTileName: installServiceData?.name,
              ["CustomerFacingBrand_s"]: installServiceData?.customerBrand,
              availableQuantity: 1,
              sku_s: installServiceData?.customerFacingSKU,
              productParentImage: `${installServiceData?.swatchUrl}${installServiceData?.images}?$SellModalDesktop$`,
            }
          : ""

        const requiredItemsArray = !_isEmpty(installServiceDataAnlytics)
          ? [installServiceDataAnlytics, ...requiredItems]
          : [...requiredItems]
        const productDataForAnlytics = {
          parentProductData: cartProductData,
          requiredItems: requiredItemsArray,
        }

        addAnalyticsWhenupsellvisible(
          recommendationTitle,
          productDataForAnlytics,
          persona
        )
        window.isUpsellAnalaticsLoaded = true
      }
    }
  }

  if (!loading && isDataAvailToProceed) {
    return (
      <div className={styles.upsellmodal}>
        <div
          className={`upsell-modal ${
            isForPostPurchase && !occupyFullWidth
              ? "upsellmodal-order-history"
              : ""
          }`}
        >
          <div className="upsell-modal-header">
            <div className="upsell-modal-header__title">
              {userTitleUpSell} {firstName}
            </div>
            <div className="upsell-modal-header__rightside">
              <div className="upsell-modal-header__rightside__zipcode-msg">
                {zipLabelUpSell}
              </div>
              <div className="rightside-for-mobile">
                {!showInput ? (
                  <div className="zipcode-if-available">{zipCodeVal}</div>
                ) : (
                  <Input
                    id="zipcode-upsell-modal"
                    type="text"
                    maxLength={5}
                    label={"ZIP Code"}
                    value={zipCodeVal}
                    onChange={handleZipChange}
                  />
                )}
                {!showInput ? (
                  <p
                    tabIndex="0"
                    role="link"
                    className="zipcode-change-link"
                    onClick={() => handleZipSerches(zipChangeLabelUpSell, true)}
                  >
                    {zipChangeLabelUpSell}
                  </p>
                ) : (
                  <div className="zipcode-serach-link">
                    <a role="button" onClick={() => handleZipSerches(search)}>
                      {search}
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
          {isUpsellDataAvailable && upSellData.length ? (
            <UpSellInstallServicesCarousalSlick
              upSellData={upSellData}
              authData={getUpSellAuthData(authData, isRequireToLookupFullData)}
              handleAddToCart={handleAddToCart}
              slickRef={slickRef}
              addServiceToCart={addServiceToCart}
              isSearchClick={isSearchClick}
            />
          ) : (
            <ProductCardForUpsell authData={productCardAuthData} />
          )}
        </div>
      </div>
    )
  }
  return null
}

export default memo(UpSellInstallServices)
